<template>
  <v-app>
    <h1 class="ma-2 ml-0">Contas</h1>
    <v-card v-if="accounts.length">
      <v-card-text
        class="d-flex align-center justify-end flex-wrap py-sm-2 py-6"
      >
        <div :class="{ 'w-full': isMobile }">
          <v-btn
            class="my-2 text-capitalize"
            color="success"
            :block="isMobile"
            @click="newAccount()"
          >
            <v-icon>
              {{ icons.mdiPlus }}
            </v-icon>
            Nova Conta
          </v-btn>
        </div>
      </v-card-text>
      <v-card-text class="pa-0">
        <v-data-table
          :items-per-page="25"
          :page.sync="page"
          :headers="tableColumns"
          :items="accounts"
          :loading="loading"
          loading-text="Carregando tabela..."
          @page-count="pageCount = $event"
          :footer-props="{
            itemsPerPageOptions: [25, 50, 100, -1],
            itemsPerPageText: 'Itens por pagina',
            itemsPerPageAllText: 'Todos',
            disablePagination: true,
            nextIcon: '',
            prevIcon: '',
            pageText: '',
          }"
        >
          <template v-slot:item.balance="{ item }">
            <span style="color: lightgreen">
              {{ parseCurrency(item.balance) }}
            </span>
          </template>

          <template v-slot:item.bank="{ item }">
            <span v-if="item.bank">
              {{ item.bank }}
            </span>
            <span v-else style="opacity: 0.5"> --- </span>
          </template>

          <template v-slot:item.status="{ item }">
            <v-chip
              v-if="item.status == 0"
              small
              text-color="white"
              color="error"
            >
              Inativo
            </v-chip>

            <v-chip v-else small text-color="white" color="success">
              Ativo
            </v-chip>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="editAccount(item)">
                  <v-list-item-title>
                    <v-icon size="20" class="me-2">
                      {{ icons.mdiFileDocumentOutline }}
                    </v-icon>
                    <span>Editar</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="item.status == 1"
                  link
                  @click="openAlertDelete(item)"
                >
                  <v-list-item-title>
                    <v-icon size="20" class="me-2">
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                    <span>Desativar</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-else link @click="openAlertDelete(item)">
                  <v-list-item-title>
                    <v-icon size="20" class="me-2">
                      {{ icons.mdiRestore }}
                    </v-icon>
                    <span>Reativar</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>

          <template v-slot:no-data>
            <nothing-component></nothing-component>
          </template>

          <template v-slot:footer class="d-flex">
            <v-pagination v-model="page" :length="pageCount"></v-pagination>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <nothing-component v-if="!accounts.length && !loading">
      <v-btn
        class="my-2 text-capitalize"
        color="success"
        :block="isMobile"
        @click="newAccount()"
      >
        <v-icon>
          {{ icons.mdiPlus }}
        </v-icon>
        Nova Conta
      </v-btn>
    </nothing-component>
    <div v-if="loading" class="d-flex justify-center">
      <v-progress-circular indeterminate></v-progress-circular>
    </div>

    <account-form ref="form" @onSubmit="getAccounts" />
    <alert ref="alert" @inactive="updateStatusAccount($event)" @active="updateStatusAccount($event)"></alert>
  </v-app>
</template>

<script>
import {
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiRestore,
} from "@mdi/js";

import AccountForm from "@/components/financial/Accounts/form/AccountForm.vue";
import Alert from "@/components/app/alert/Alert.vue";
import NothingComponent from "@/components/nothingComponent.vue";

const formatador = Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
  minimumFractionDigits: 2,
});

export default {
  components: { AccountForm, NothingComponent, Alert },

  data() {
    return {
      loading: false,
      page: null,
      pageCount: null,

      accounts: [],

      tableColumns: [
        { text: "Descrição", value: "description" },
        { text: "Banco", value: "bank" },
        { text: "Agencia", value: "bank_agency" },
        { text: "Conta", value: "bank_account" },
        { text: "Balanço", value: "balance" },
        { text: "status", value: "status" },
        { text: "Ações", value: "actions" },
      ],

      icons: {
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiFileDocumentOutline,
        mdiPlus,
        mdiRestore,
      },
    };
  },

  mounted() {
    this.getAccounts();
  },

  methods: {
    getAccounts() {
      this.loading = true;

      this.$http
        .$get("/account")
        .then((response) => {
          if (response.status == 200) {
            this.accounts = response.data.data;
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    newAccount() {
      this.$refs.form.open();
    },

    editAccount(item) {
      this.$refs.form.open(item.id);
    },

    openAlertDelete(row) {
      this.$refs.alert.open(row);
    },

    updateStatusAccount(content){
      this.$http
      .$put(`/account/${content.id}`, content ).then( res => {
          console.log(res);
          this.getAccounts();
      }).catch(err => {
          console.log(err);
      })
    },

    parseCurrency(num) {
      return formatador.format(num / 100);
    },
  },
};
</script>