<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    origin="center center"
    persistent
    :fullscreen="isMobile"
  >
    <v-card>
      <v-card-title>
        <v-icon class="mr-1">
          {{ icons.mdiAccount }}
        </v-icon>
        {{ isUpdating ? "Editar" : "Cadastrar" }} Conta
      </v-card-title>
      <v-divider></v-divider>

      <v-flex v-if="loading" class="d-flex justify-center">
        <v-progress-circular indeterminate color="secondary" class="ma-2" />
      </v-flex>

      <v-form v-show="!loading" v-model="formValid" ref="form">
        <v-container class="mt-6">
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="typeSelect"
                :items="typeSelectItems"
                :rules="[rules.required]"
                outlined
                dense
                label="Tipo"
                hide-details="auto"
              ></v-autocomplete>
            </v-col>

            <v-col>
              <v-combobox
                v-model="accountForm.bank"
                :disabled="!bankSelectAllow"
                :items="bankSelect"
                :rules="bankSelectAllow ? [rules.required] : []"
                outlined
                dense
                label="Banco"
                hide-details="auto"
              ></v-combobox>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                v-model="accountForm.description"
                :rules="[rules.required]"
                label="Nome da Conta"
                outlined
                dense
                hide-details="auto"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                v-model="accountForm.bank_agency"
                :disabled="!bankSelectAllow"
                :rules="bankSelectAllow ? [rules.required] : []"
                label="Agencia"
                outlined
                dense
                hide-details="auto"
              />
            </v-col>

            <v-col>
              <v-text-field
                v-model="accountForm.bank_account"
                :disabled="!bankSelectAllow"
                :rules="bankSelectAllow ? [rules.required] : []"
                label="Conta"
                outlined
                dense
                hide-details="auto"
              />
            </v-col>

            <v-col>
              <money-input
                ref="balanceInputRef"
                label="Valor"
                v-model="accountForm.balance"
                :rules="[rules.required]"
              ></money-input>
            </v-col>
          </v-row>
        </v-container>
      </v-form>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" class="text-capitalize" @click="close">
          Cancelar
        </v-btn>

        <v-btn class="text-capitalize" color="success" @click="submitForm">
          <v-icon> {{ icons.mdiContentSave }} </v-icon>
          Salvar Informações
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { OPTIONS_BANKS } from "@/constants/banks";
import { mdiAccount, mdiContentSave } from "@mdi/js";

import moneyInput from "@/components/app/ui/moneyInput.vue";
import formMixin from "@/util/mixins/formMixin";

export default {
  components: {
    moneyInput,
  },

  mixins: [formMixin],

  data() {
    return {
      dialog: false,
      formValid: null,
      isUpdating: false,
      loading: null,

      typeSelect: null,
      typeSelectItems: ["Conta Gerencial", "Conta Bancaria"],
      accountForm: {
        balance: "0,00",
        bank_code: null,
        bank: null,
        bank_account: null,
        bank_agency: null,
        company_id: null,
        created_at: null,
        description: null,
        id: null,
        status: 1,
        tenant_id: null,
        type: null,
        updated_at: null,
      },

      bankSelect: OPTIONS_BANKS.map((bank) => {
        return bank.fullName;
      }),

      icons: {
        mdiAccount,
        mdiContentSave,
      },
    };
  },

  computed: {
    bankSelectAllow() {
      return this.accountForm.type == "bank";
    },
  },

  watch: {
    dialog() {
      if (!this.dialog) {
        this.accountForm = {
          balance: "0,00",
          bank_code: null,
          bank: null,
          bank_account: null,
          bank_agency: null,
          company_id: null,
          created_at: null,
          description: null,
          id: null,
          status: 1,
          tenant_id: null,
          type: null,
          updated_at: null,
        };

        this.$refs.form.reset();
        this.$refs.balanceInputRef.reset();
      }
    },

    typeSelect() {
      this.accountForm.type = this.typeSelect == "Conta Gerencial" ? "wallet" : "bank";
    },

    "accountForm.type": "checkType",
  },

  methods: {
    open(id = null) {
      this.dialog = true;
      this.isUpdating = id != null;

      if (id) {
        this.getAccount(id);
      }
    },

    close() {
      this.dialog = false;
    },

    getAccount(id) {
      this.loading = true;

      this.$http.$get(`/account/${id}`).then((response) => {
        this.accountForm = Object.assign({}, response.data);
        this.typeSelect = this.accountForm.type === "wallet" ? "Conta Gerencial" : "Conta Bancaria";

        this.$refs.balanceInputRef.reset(this.accountForm.balance);
        this.loading = false;
      });
    },

    submitForm() {
      if (this.$refs.form.validate()) {
        this.getBankCode();
        console.log(this.accountForm);
        if (this.isUpdating) {
          this.$http
            .$put("/account/" + this.accountForm.id, this.accountForm)
            .then((response) => {
              if (response.status == 200) {
                this.$emit("onSubmit");
                this.dialog = false;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.$http
            .$post("/account", this.accountForm)
            .then((response) => {
              if (response.status == 200) {
                this.$emit("onSubmit");
                this.dialog = false;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },

    checkType() {
      if (this.accountForm.type == "wallet") {
        this.accountForm.bank = null;
        this.accountForm.bank_agency = null;
        this.accountForm.bank_account = null;
      }
    },
    getBankCode() {
      if (this.accountForm.bank) {
        const selectedBank = OPTIONS_BANKS.find((bank) => bank.fullName === this.accountForm.bank);
        if (selectedBank) {
          this.accountForm.bank_code = selectedBank.code;
        }
      }
    },
  },
};
</script>

<style>
</style>