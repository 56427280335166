var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('h1',{staticClass:"ma-2 ml-0"},[_vm._v("Contas")]),(_vm.accounts.length)?_c('v-card',[_c('v-card-text',{staticClass:"d-flex align-center justify-end flex-wrap py-sm-2 py-6"},[_c('div',{class:{ 'w-full': _vm.isMobile }},[_c('v-btn',{staticClass:"my-2 text-capitalize",attrs:{"color":"success","block":_vm.isMobile},on:{"click":function($event){return _vm.newAccount()}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_vm._v(" Nova Conta ")],1)],1)]),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"items-per-page":25,"page":_vm.page,"headers":_vm.tableColumns,"items":_vm.accounts,"loading":_vm.loading,"loading-text":"Carregando tabela...","footer-props":{
          itemsPerPageOptions: [25, 50, 100, -1],
          itemsPerPageText: 'Itens por pagina',
          itemsPerPageAllText: 'Todos',
          disablePagination: true,
          nextIcon: '',
          prevIcon: '',
          pageText: '',
        }},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.balance",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticStyle:{"color":"lightgreen"}},[_vm._v(" "+_vm._s(_vm.parseCurrency(item.balance))+" ")])]}},{key:"item.bank",fn:function(ref){
        var item = ref.item;
return [(item.bank)?_c('span',[_vm._v(" "+_vm._s(item.bank)+" ")]):_c('span',{staticStyle:{"opacity":"0.5"}},[_vm._v(" --- ")])]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [(item.status == 0)?_c('v-chip',{attrs:{"small":"","text-color":"white","color":"error"}},[_vm._v(" Inativo ")]):_c('v-chip',{attrs:{"small":"","text-color":"white","color":"success"}},[_vm._v(" Ativo ")])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.editAccount(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentOutline)+" ")]),_c('span',[_vm._v("Editar")])],1)],1),(item.status == 1)?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.openAlertDelete(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v("Desativar")])],1)],1):_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.openAlertDelete(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiRestore)+" ")]),_c('span',[_vm._v("Reativar")])],1)],1)],1)],1)]}},{key:"no-data",fn:function(){return [_c('nothing-component')]},proxy:true},{key:"footer",fn:function(){return [_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})]},proxy:true}],null,false,2532209868)})],1)],1):_vm._e(),(!_vm.accounts.length && !_vm.loading)?_c('nothing-component',[_c('v-btn',{staticClass:"my-2 text-capitalize",attrs:{"color":"success","block":_vm.isMobile},on:{"click":function($event){return _vm.newAccount()}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_vm._v(" Nova Conta ")],1)],1):_vm._e(),(_vm.loading)?_c('div',{staticClass:"d-flex justify-center"},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):_vm._e(),_c('account-form',{ref:"form",on:{"onSubmit":_vm.getAccounts}}),_c('alert',{ref:"alert",on:{"inactive":function($event){return _vm.updateStatusAccount($event)},"active":function($event){return _vm.updateStatusAccount($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }